// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";
import Alpine from "alpinejs";

if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
  Alpine.data("getBooks", () => ({
    books: [],
    search: "",
    async init() {
      const apiUrl = process.env.BASEROW_URL;
      const apiKey = process.env.BASEROW_API_KEY;

      const headers = new Headers();
      headers.append("Authorization", `Token ${apiKey}`);
      this.isLoading = true;

      fetch(apiUrl, { headers })
        .then((response) => response.json())
        .then((response) => {
          this.isLoading = false;
          this.books = response.results;
        })
        .catch((err) => console.log(err));
    },
    // https://stackoverflow.com/questions/75167443/making-a-search-bar-work-in-alpinejs-where-the-searched-items-in-x-data-come-fro
    get filteredItems() {
      return this.books.filter(
        (i) =>
          // title
          i.field_40.toLowerCase().includes(this.search.toLowerCase()) ||
          // ISBN
          i.field_42.toLowerCase().includes(this.search.toLowerCase()) ||
          // Author
          i.field_39.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  }));
});

Alpine.start();

// Bootstrap tooltips
const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
[...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

// AOS

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();
